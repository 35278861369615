import React, { useState } from "react";
import { FaBars, FaTimes, FaLinkedin, FaGithub } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from 'react-icons/bs'
import Joshua from "../files/joshua.jpg";
import Logo from "../files/Joshua Harris (1).png"
import { Link } from "react-scroll";

const Navbar = () => {
  const [nav, setNav] = useState(false)
  const handleClick = () => setNav(!nav)

  return (
    <div className="fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300">
      <div>
        <img src={Logo} alt="JRH Logo" style={{ width: "50px" }} />
      </div>

      {/* menu */}
      <div className="hidden md:flex">
        <ul className="md:flex">
        <li className="py-6 text-4xl">
        <Link to="home" smooth={true} duration={500}>
          Home
        </Link>
        </li>
        <li className="py-6 text-4xl">
        <Link to="about" smooth={true} duration={500}>
          About
        </Link>
        </li>
        <li className="py-6 text-4xl">
        <Link to="skills" smooth={true} duration={500}>
          Skills
        </Link>
        </li>
        <li className="py-6 text-4xl">
        <Link to="projects" smooth={true} duration={500}>
          Projects
        </Link>
        </li>
        <li className="py-6 text-4xl">
        <Link to="contact" smooth={true} duration={500}>
          Contact
        </Link>
        </li>

        </ul>
      </div>
      {/* Hamburger */}
      <div onClick={handleClick} className="md:hidden z-10">
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* Mobile Menu */}
      
        <ul className= {!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center itmes-center'}>
        <li className='py-6 text-4xl'>
          <Link onClick={handleClick} to='home' smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='about' smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='skills' smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='projects' smooth={true} duration={500}>
            Projects
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='contact' smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>

      {/* Socials */}
      <div className="hidden lg:flex fixed flex-col top-[35%] left-0">
        <ul>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600">
            <a className='flex justify-between items-center w-full text-gray-300' href="https://www.linkedin.com/in/joshuareginaldharris/" target="_blank">
              LinkedIn <FaLinkedin size={30}/>
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]">
            <a className='flex justify-between items-center w-full text-gray-300' href="https://github.com/joshuaharris1103" target="_blank">
              Github <FaGithub size={30}/>
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]">
            <a className='flex justify-between items-center w-full text-gray-300' href="mailto: joshuarh.dev@gmail.com">
              Email <HiOutlineMail size={30}/>
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]">
            <a className='flex justify-between items-center w-full text-gray-300' href="https://docs.google.com/document/d/1y5WxyzuLuAfRz-Xa7y5xLBlUAhCfze7U/edit?usp=sharing&ouid=112666961400120615124&rtpof=true&sd=true" target="_blank">
              Resume <BsFillPersonLinesFill size={30}/>
            </a>
          </li>
        </ul>

      </div>
    </div>
  );
};

export default Navbar;
